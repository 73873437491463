<template>
	<div class="blig-pdf-wdt">
		<header class="brand-top-section position-relative">
			<div class="d-inline-block">
				<div class="lft-logopart" style="margin-top:6px;">
					<img src="/images/e-chart-transfer.svg" class="img-fluid e-chart-t-toplogo" alt="Billing Receipt">
				</div>
				<div class="rgt-loct-part">
					<div class="text-end">
						<p class="mb-0 mdcal-cntr">{{ clinicData.organization_name ?
							clinicData.organization_name.toUpperCase() : '' }}</p>
						<p class="mb-0 cmpn-add">
							{{ clinicData.address ? clinicData.address.toUpperCase() : '' }},
							<br>
							{{ clinicData.city ? clinicData.city.toUpperCase() : '' }},
							{{ clinicData.state ? clinicData.state.toUpperCase() : '' }}
							{{ clinicData.zipcode ? clinicData.zipcode : '' }}
							<br>
							<span class="fw-600">TEL</span>
							{{ clinicData.phone_number ? clinicData.phone_number : '' }}
							<span class="fw-600 ms-2">FAX</span>
							{{ clinicData.fax ? clinicData.fax : '' }}
						</p>
					</div>
				</div>
			</div>
		</header>
		<div class="divider-line"></div>
		<div class="position-relative">
			<div class="main-lft-brd">
				<img src="/images/polygon-2.svg" class="dots-polygon-01">
				<div class="billing-dtls" style="padding-top: 30px;" v-if="formData.is_demographic">
					<p class="bill-to-nm mb-0">Basic Info <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
				</div>
				<div class="d-inline-block bsc-leftpart float-start" v-if="formData.is_demographic">
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">patient</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-700">{{ this.demographicData.patient_name }}</p>
							<p class="mb-0 fw-300">{{ this.demographicData.residential_address }}
								<br>
								{{ this.demographicData.residential_city }}, {{
								this.demographicData.residential_province_name }} {{
								this.demographicData.residential_zip }}<br>
							</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">gender</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ this.demographicData.gender }}</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">date of birth</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ this.demographicData.dob }}</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">hin</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ this.demographicData.hin_details }}</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">cell phone</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ this.demographicData.cell_phone }}</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">home phone</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ this.demographicData.home_phone }}</p>
						</div>
					</div>
					<div>
						<div class="work-pn-lft">
							<div class="ptnt-blig-detls">
								<p class="basic-ttl mb-0">work phone</p>
								<div class="loct-pt-rg">
									<p class="mb-0 fw-300">{{ this.demographicData.work_phone }}</p>
								</div>
							</div>
						</div>
						<div class="ext-pn-rgt">
							<div class="ptnt-blig-detls m-0">
								<p class="basic-ttl mb-0" style="width: 20px;padding-right: 0;margin-right: 6px;">ext.
								</p>
								<div class="loct-pt-rg">
									<p class="mb-0 fw-300">{{ this.demographicData.work_phone_ext }}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">email</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ this.demographicData.email }}</p>
						</div>
					</div>
				</div>
				<div class="d-inline-block bsc-rightpart" v-if="formData.is_demographic">
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">alternative contact 1</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-700">
								{{ (alternativeContactDetails && alternativeContactDetails[0] &&
								alternativeContactDetails[0].last_name && alternativeContactDetails[0].first_name)
								? (alternativeContactDetails[0].last_name.toUpperCase() + ' ' +
								alternativeContactDetails[0].first_name.toUpperCase()) : '' }}
							</p>
							<p class="mb-0 fw-300">
								{{
								alternativeContactDetails?.[0]?.contact_purpose === 'SDM'
								? 'SDM=Substitute Decision Maker'
								: alternativeContactDetails?.[0]?.contact_purpose === 'EC'
								? 'EC=Emergency Contact'
								: alternativeContactDetails?.[0]?.contact_purpose || ''
								}}
								<br> {{ alternativeContactDetails?.[0]?.relationship || '' }}
							</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">contact 1 cell phone</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ alternativeContactDetails?.[0]?.cell_phone || '' }}</p>
						</div>
					</div>
					<div>
						<div class="work-pn-lft">
							<div class="ptnt-blig-detls">
								<p class="basic-ttl mb-0">contact 1 work phone</p>
								<div class="loct-pt-rg">
									<p class="mb-0 fw-300">{{ alternativeContactDetails?.[0]?.work_phone || '' }}</p>
								</div>
							</div>
						</div>
						<div class="ext-pn-rgt">
							<div class="ptnt-blig-detls m-0">
								<p class="basic-ttl mb-0" style="width: 20px;padding-right: 0;margin-right: 6px;">ext.
								</p>
								<div class="loct-pt-rg">
									<p class="mb-0 fw-300">{{ alternativeContactDetails?.[0]?.work_phone_ext || '' }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">contact 1 home phone</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ alternativeContactDetails?.[0]?.residence_phone || '' }}</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">contact 1 email</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ alternativeContactDetails?.[0]?.email || '' }}</p>
						</div>
					</div>
					<div class="ptnt-blig-detls" style="margin-top: 20px;margin-bottom: 30px;">
						<p class="basic-ttl mb-0">alternative contact 2</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">
								{{ (alternativeContactDetails && alternativeContactDetails[1] &&
									alternativeContactDetails[1].last_name && alternativeContactDetails[1].first_name)
									? (alternativeContactDetails[1].last_name.toUpperCase() + ' ' +
										alternativeContactDetails[1].first_name.toUpperCase()) : '' }}
							</p>
							<p class="mb-0 fw-300">
								{{
								alternativeContactDetails?.[1]?.contact_purpose === 'SDM'
								? 'SDM=Substitute Decision Maker'
								: alternativeContactDetails?.[1]?.contact_purpose === 'EC'
								? 'EC=Emergency Contact'
								: alternativeContactDetails?.[1]?.contact_purpose || ''
								}}
								<br> {{ alternativeContactDetails?.[1]?.relationship || '' }}
							</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">contact 2 cell phone</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ alternativeContactDetails?.[1]?.cell_phone || '' }}</p>
						</div>
					</div>
					<div>
						<div class="work-pn-lft">
							<div class="ptnt-blig-detls">
								<p class="basic-ttl mb-0">contact 2 work phone</p>
								<div class="loct-pt-rg">
									<p class="mb-0 fw-300">{{ alternativeContactDetails?.[1]?.work_phone || '' }}</p>
								</div>
							</div>
						</div>
						<div class="ext-pn-rgt">
							<div class="ptnt-blig-detls m-0">
								<p class="basic-ttl mb-0" style="width: 20px;padding-right: 0;margin-right: 6px;">ext.
								</p>
								<div class="loct-pt-rg">
									<p class="mb-0 fw-300">{{ alternativeContactDetails?.[1]?.work_phone_ext || '' }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">contact 2 home phone</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ alternativeContactDetails?.[1]?.residence_phone || '' }}</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="basic-ttl mb-0">contact 2 email</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-300">{{ alternativeContactDetails?.[1]?.email || '' }}</p>
						</div>
					</div>
				</div>
				<div class="sevs-ttl mt-0" v-if="formData.is_encounter_notes">
					<p class="bill-to-nm mb-0">encounter notes <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
				</div>
				<div class="encuntr-nts-table" v-if="formData.is_encounter_notes">
					<table class="table">
						<thead>
							<tr>
								<th style="width:78px;">date</th>
								<th style="width: 135px;">provider</th>
								<th>encounter notes</th>
							</tr>
						</thead>
						<tbody style="height:auto;">
							<tr v-for="(note, index) in encounterNotesData" :key="index">
								<td>{{ note.encounter_date }}</td>
								<td>{{ note.provider }}</td>
								<td v-html="note.note"></td>
							</tr>
							<!-- <tr>
								<td>2024-01-01</td>
								<td>HONG, JAMES</td>
								<td>S: Mother here with son as interpreter, all poor historians, pt has no gp or
									peds<br>
									O: looks well. bright eyes, respond to commands, a bit hyperactive<br>
									A: concerns with speech, no peds<br>
									P: child appears bright, no dev concerns today, referral to ENT for eharing and peds
								</td>
							</tr> -->
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="" style="position: absolute;bottom: 40px;right: 4px;">
			<div class="position-relative" style="">
				<div class="and-brd-w"></div>
				<div class="and-brd-h"></div>
				<img src="/images/polygon-2.svg" class="dots-polygon">
			</div>
		</div>
		<div class="docttypesect text-end" style="">
			<p class="mb-0 doct-gnrt-tag">Document Generated By |</p>
			<img :src="logoUrl" class="img-fluid">
		</div>
	</div>

	<div style="page-break-before:always">&nbsp;</div>

	<div class="blig-pdf-wdt" v-if="formData.is_measurements === true || formData.is_files === true || 
				formData.is_medication === true || formData.is_other_infos === true || 
				formData.is_preventions === true || formData.is_screening === true">
		<header class="brand-top-section position-relative">
			<div class="d-inline-block">
				<div class="lft-logopart" style="margin-top:6px;">
					<img src="/images/e-chart-transfer.svg" class="img-fluid e-chart-t-toplogo" alt="Billing Receipt">
				</div>
				<div class="rgt-loct-part">
					<div class="text-end">
						<p class="mb-0 mdcal-cntr">{{ clinicData.organization_name ?
							clinicData.organization_name.toUpperCase() : '' }}</p>
						<p class="mb-0 cmpn-add">
							{{ clinicData.address ? clinicData.address.toUpperCase() : '' }},
							<br>
							{{ clinicData.city ? clinicData.city.toUpperCase() : '' }},
							{{ clinicData.state ? clinicData.state.toUpperCase() : '' }}
							{{ clinicData.zipcode ? clinicData.zipcode : '' }}
							<br>
							<span class="fw-600">TEL</span>
							{{ clinicData.phone_number ? clinicData.phone_number : '' }}
							<span class="fw-600 ms-2">FAX</span>
							{{ clinicData.fax ? clinicData.fax : '' }}
						</p>
						<!-- <p class="mb-0 cmpn-add">3420 MAJOR MACKENZIE DR WEST, UNIT 101/102,<br>
							WOODBRDIGE, ONTARIO L4H 4J6<br>
							<span class="fw-600">TEL</span> 905-222-2222 <span class="fw-600 ms-2">FAX</span>
							905-222-2222
						</p> -->
					</div>
				</div>
			</div>
		</header>
		<div class="divider-line"></div>
		<div class="position-relative">
			<div class="main-lft-brd">
				<img src="/images/polygon-2.svg" class="dots-polygon-01">
				<div class="billing-dtls" style="padding-top: 30px;" v-if="formData.is_measurements">
					<p class="bill-to-nm mb-0">measurements <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
				</div>
				<div class="d-inline-block w-100" v-if="formData.is_measurements">
					<div class="sevs-tablelist" style="font-size: 8px;">
						<table class="table">
							<thead>
								<tr>
									<th style="width:168px;">metric</th>
									<th style="width:180px;">recorded date</th>
									<th class="text-end" style="width:120px;">values</th>
									<th class="text-end" style="width:165px;">unit</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(measurements, index) in measurementsData" :key="index">
									<td>{{ measurements.metric }}</td>
									<td>{{ measurements.recorded_date }}</td>
									<td class="text-end">{{ measurements.value }}</td>
									<td class="text-end">{{ measurements.unit }}</td>
								</tr>
								<!-- <tr>
									<td>Weight</td>
									<td>2024-01-01</td>
									<td class="text-end">85</td>
									<td class="text-end">kg</td>
								</tr> -->
							</tbody>
						</table>
					</div>
				</div>
				<div class="sevs-ttl mt-0" v-if="formData.is_medication">
					<p class="bill-to-nm mb-0">medications <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
				</div>
				<div class="d-inline-block w-100" v-if="formData.is_medication">
					<div class="sevs-tablelist" style="font-size: 8px;">
						<table class="table">
							<thead>
								<tr>
									<th style="width:88px;">start date</th>
									<th style="width:209px;">generic name</th>
									<th style="width:227px;">brand name</th>
									<th class="text-end" style="width:88px;">provider</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(medications, index) in medicationData" :key="index">
									<td>{{ medications.start_date }}</td>
									<td>{{ medications.generic_name }}</td>
									<td>{{ medications.drug_name }}</td>
									<td class="text-end">{{ (medications?.last_name + ' ' +
										medications?.first_name)?.toUpperCase() || '' }}</td>
								</tr>
								<!-- <tr>
									<td>2024-01-01</td>
									<td>BETA-CAROTENE 3000.0 UNIT / VITAMIN A (VITAMIN A ACETATE) 2000 DOSAGE</td>
									<td>18 ESSENTIAL VITAMINS AND MINERALS</td>
									<td class="text-end">HONG JAMES</td>
								</tr> -->
							</tbody>
						</table>
					</div>
				</div>

				<div class="sevs-ttl mt-0" v-if="formData.is_preventions">
					<p class="bill-to-nm mb-0">preventions <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
				</div>
				<div class="d-inline-block w-100" v-if="formData.is_preventions">
					<div class="sevs-tablelist" style="font-size: 8px;">
						<table class="table">
							<thead>
								<tr>
									<th style="width:88px;">date</th>
									<th style="width:168px;">vaccine name</th>
									<th style="width:180px;">brand name</th>
									<th class="text-end" style="width:60px;">dose</th>
									<th class="text-end" style="width:76px;">route</th>
									<th class="text-end" style="width:70px;">provider</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(preventions, index) in preventionsData" :key="index">
									<td>{{ preventions.encounter_date }}</td>
									<td>{{ preventions.vaccine_name }}</td>
									<td>{{ preventions.brand_name }}</td>
									<td class="text-end">{{ preventions.dose }}</td>
									<td class="text-end">{{ preventions.route }}</td>
									<td class="text-end">{{ preventions.provider }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="sevs-ttl mt-0" v-if="formData.is_screening">
					<p class="bill-to-nm mb-0">screenings <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
				</div>
				<div class="d-inline-block w-100" v-if="formData.is_screening">
					<div class="sevs-tablelist" style="font-size: 8px;">
						<table class="table">
							<thead>
								<tr>
									<th style="width:88px;">date</th>
									<th style="width:168px;">screening type</th>
									<th class="text-end" style="width:200px;">status</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(screenings, index) in screeningData" :key="index">
									<td>{{ screenings.encounter_date }}</td>
									<td>{{ screenings.screening_type }}</td>
									<td class="text-end">{{ screenings.status }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>


				<div class="sevs-ttl mt-0" v-if="formData.is_other_infos">
					<p class="bill-to-nm mb-0">other infos <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
				</div>
				<div class="d-inline-block w-100" v-if="formData.is_other_infos">
					<div class="sevs-tablelist" style="font-size: 8px;">
						<table class="table">
							<thead>
								<tr>
									<th style="width:110px;">module</th>
									<th style="width:124px;">start date</th>
									<th>notes / problem description / diagnosis</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(otherInfos, index) in otherInfoData" :key="index">
									<td>{{ otherInfos.module }}</td>
									<td>{{ otherInfos.start_date || '' }}</td>
									<td>{{ otherInfos.description }}</td>
								</tr>
								<!-- <tr>
									<td>Family History</td>
									<td>2022-08-11</td>
									<td>grandmother | died of Type II diabetes (mother side)</td>
								</tr>
								<tr>
									<td>Family History</td>
									<td>2022-08-11</td>
									<td>grandfather | high blood pressure</td>
								</tr> -->
							</tbody>
						</table>
					</div>
				</div>

				<div class="sevs-ttl mt-0" v-if="formData.is_files">
					<p class="bill-to-nm mb-0">files <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
				</div>
				<p class="fw-600" style="font-size:8px;margin-left: 30px;" v-if="fileData.length > 1 && formData.is_files">all of this
					patient’s files are attached at
					the back of this report.</p>

			</div>
		</div>
		<div class="" style="position: absolute;bottom: 40px;right: 4px;">
			<div class="position-relative" style="">
				<div class="and-brd-w"></div>
				<div class="and-brd-h"></div>
				<img src="/images/polygon-2.svg" class="dots-polygon">
			</div>
		</div>
		<div class="docttypesect text-end" style="">
			<p class="mb-0 doct-gnrt-tag">Document Generated By |</p>
			<img :src="logoUrl" class="img-fluid">
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";
export default ({
	data() {
		return {
			patient_id: this.$route.params.patient_id,
			form_data: {},
			demographicData: [],
			alternativeContactDetails: [],
			encounterNotesData: [],
			fileData: [],
			measurementsData: [],
			medicationData: [],
			otherInfoData: [],
			preventionsData: [],
			screeningData: [],
			clinicData: [],
			formData: {},
		}
	},
	created() {
		// Access query parameter from URL
        const formDataParam = this.$route.query.form_data;
        if (formDataParam) {
            // Decode and parse the form_data parameter
            this.formData = JSON.parse(decodeURIComponent(formDataParam));
            
            // Example: Call API to fetch data based on formData
			this.$store.state.loader = true;
            this.fetchData(this.formData);
        }
	},
	computed: {
		logoUrl() {
			return this.clinicData.logo ? `/storage/${this.clinicData.logo}` : '/images/black-font-Logo.svg';
		}
	},
	methods: {
		fetchData(formData) {
            axios.post('patient/e-chart-print', { patient_id: this.patient_id, data: formData })
                .then(response => {
					console.log('response:- ', response);
					if(response.status == 200)
					{
						if(response.data.data)
						{
							const responseData = response.data.data;
							this.demographicData = responseData.demographicData;
							this.alternativeContactDetails = responseData.demographicData.alternative_contact_detail;
							this.encounterNotesData = responseData.encounterNotesData;
							this.measurementsData = responseData.measurementsData; 
							this.medicationData = responseData.medicationData; 
							this.preventionsData = responseData.preventionsData;
							this.screeningData = responseData.screeningData;
							this.otherInfoData = responseData.otherInfoData;
							this.fileData = responseData.filesData;
							this.clinicData = responseData.clinicData;
							this.$store.state.loader = false;
							this.$nextTick(() => {
								setTimeout(() => {
									this.printPreview();
								}, 2000);
							});
						}
					}
                    this.form_data = response.data;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },
		printPreview() {
			window.print();

			// Set a timeout to check if the print dialog is closed
			const checkPrintDialogClosed = setInterval(() => {
				// Check if the print dialog is closed (not a reliable method)
				// This check is a bit of a hack since there's no reliable way to check this
				if (!document.hidden) {
					clearInterval(checkPrintDialogClosed); // Stop checking
					this.closeTab(); // Close the tab
				}
			}, 500); // Check every 500 ms
		},
		closeTab() {
			// Attempt to close the current tab
			window.close();
		}
	},
	mounted() {
	}
});
</script>